export const environment = {
  production: true,
  showVersion: false,
  appUrl: 'https://www.otopod.ca',
  apiUri: 'https://api.otopod.ca',
  auth0ClientId: 'qhOmV8h8ZWz224VDc6bLG1RM8jm8CcA5',
  auth0Domain: 'otopod.us.auth0.com',
  auth0Audience: 'https://otopod.ca/',
  appInsights: {
    instrumentationKey: 'cf939ccc-d955-401a-b274-b5a5faaf8337',
  },
  stripe: {
    publishableKey: 'pk_live_OvwZIez38deDBRwvAJ7hKIS900HqHZgRAU',
  },
  facebookPath: 'https://www.facebook.com/FormationsOtopod/',
  linkedInPath: 'https://www.linkedin.com/company/otopod/',
  contactMailToPath: 'info@otopod.ca',
  marcoBernardFormationId: { fr: '5ffb68b8052deed69f90a92e', en: '66c6307bf734903e0c3698a9' },
  freeCategoryId: '600074e7c06b0670c86b54d1',
  facebookPixelId: '213443690482517',
};
