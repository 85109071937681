import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '~/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState, selectUser, updateUser } from '~/store';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'otopod-communication-preference-dialog',
  templateUrl: './communication-preference-dialog.component.html',
  styleUrls: ['./communication-preference-dialog.component.scss'],
})
export class CommunicationPreferenceDialogComponent implements OnDestroy, OnInit {
  communicationPreference: 'fr' | 'en' | 'none' | undefined;

  private user: User;
  private destroy$ = new Subject();

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<CommunicationPreferenceDialogComponent>,
    @Inject(LOCALE_ID) public locale: string
  ) {
    if (this.locale === 'en') this.communicationPreference = 'en';
    else this.communicationPreference = 'fr';
  }

  ngOnInit() {
    this.store
      .select(selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  save() {
    if (this.communicationPreference !== undefined) {
      this.store.dispatch(
        updateUser({
          user: { ...this.user, communicationPreference: this.communicationPreference },
          originalUser: { ...this.user },
        })
      );
      this.dialogRef.close();
    }
  }
}
