<h2 mat-dialog-title i18n="@@communication-preferences-2">Restons connectés!</h2>
<mat-dialog-content fxFlex.lt-sm="grow">
  <h3 i18n="@@communication-preferences-3">Des formations qui propulsent votre carrière</h3>
  <div fxLayout="row" fxLayoutAlign="center center">
    <img src="./assets/contact.svg" width="128px" />
  </div>
  <div i18n="@@communication-preferences-4">Inscrivez-vous à notre infolettre pour :</div>
  <div><img class="checked" src="./assets/checked.svg" /><span i18n="@@communication-preferences-5">Être le premier informé des nouvelles formations offertes.</span></div>
  <div><img class="checked" src="./assets/checked.svg" /><span i18n="@@communication-preferences-6">Profiter d’un contenu adapté à vos besoins professionnels.</span></div>
  <div><img class="checked" src="./assets/checked.svg" /><span i18n="@@communication-preferences-7">Ne manquer aucune opportunité de développement.</span></div>
  <div class="promis" i18n="@@communication-preferences-12">Promis, on n’abusera pas de votre boîte de réception : seulement quelques courriels par année, 100 % pertinents!</div>
  <mat-radio-group [(ngModel)]="communicationPreference">
    <mat-radio-button value="fr" i18n="@@communication-preferences-8">Francais</mat-radio-button>
    <mat-radio-button value="en" i18n="@@communication-preferences-9">Anglais</mat-radio-button>
    <mat-radio-button value="none" i18n="@@communication-preferences-10">Ne pas communiquer</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="this.save()" i18n="@@communication-preferences-11">
    Sauvegarder
  </button>
</mat-dialog-actions>
